import React, { useState } from 'react';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useLocation } from 'react-router-dom';
import { useGlobalContext } from '../Config';

const PalabrasClave = (props) => {
    const [palabras, setPalabras] = useState([
        { id: 1, palabra: 'Calefaccion', exactitud: 2 },
        { id: 2, palabra: 'Estufa', exactitud: 1 },
        { id: 3, palabra: 'Calefactores', exactitud: 2 },
        { id: 4, palabra: 'Estufa Pellet', exactitud: 1 }
    ]);
    const location = useLocation();
    const idRubro = new URLSearchParams(location.search).get('idRubro');
    const [displayAñadirExacta, setDisplayAñadirExacta] = useState(false);
    const [displayAñadirPosible, setDisplayAñadirPosible] = useState(false);
    const [displayEditarExacta, setDisplayEditarExacta] = useState(false);
    const [displayEditarPosible, setDisplayEditarPosible] = useState(false);
    const [displayEliminarExacta, setDisplayEliminarExacta] = useState(false);
    const [displayEliminarPosible, setDisplayEliminarPosible] = useState(false);
    const [selectedPalabra, setSelectedPalabra] = useState({});
    const [editedPalabra, setEditedPalabra] = useState('');
    const [newPalabraPosible, setNewPalabraPosible] = useState('');
    const [newPalabraExacta, setNewPalabraExacta] = useState('');
    const { baseUrl } = useGlobalContext();

    const handleEditarPalabraExacta = (palabra) => {
        setSelectedPalabra(palabra);
        setEditedPalabra(palabra.nombre);
        setDisplayEditarExacta(true);
    };

    const handleEditarPalabraPosible = (palabra) => {
        setSelectedPalabra(palabra);
        setEditedPalabra(palabra.nombre);
        setDisplayEditarPosible(true);
    };

    const handleCambiarExactitud = (palabra) => {
        let exactitud;
        if (palabra.descripcion === 'EXACTA') {
            exactitud = 2;
        } else if (palabra.descripcion === 'POSIBLE') {
            exactitud = 1;
        }
        const updateData = async () => {
            try {
                const response = await fetch(baseUrl + 'updatePalabraClave?idPalabraClave=' + palabra.id + '&idRubro=' + palabra.idRubro + '&palabraClave=' + palabra.nombre + '&idExactitud=' + exactitud);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log(data);

                setSelectedPalabra('');
                setDisplayEditarExacta(false);
                setDisplayEditarPosible(false);
                loadData();
            } catch (error) {
                console.error(error);
            }
        };
        updateData();
    };
    const handleGuardarPalabraExacta = () => {
        const updateData = async () => {
            try {
                const response = await fetch(baseUrl + 'updatePalabraClave?idPalabraClave=' + selectedPalabra.id + '&idRubro=' + selectedPalabra.idRubro + '&palabraClave=' + editedPalabra.toUpperCase() + '&idExactitud=1');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log(data);

                setSelectedPalabra('');
                setDisplayEditarExacta(false);
                setDisplayEditarPosible(false);
                loadData();
            } catch (error) {
                console.error(error);
            }
        };
        updateData();
    };
    const handleGuardarPalabraPosible = () => {
        const updateData = async () => {
            try {
                const response = await fetch(baseUrl + 'updatePalabraClave?idPalabraClave=' + selectedPalabra.id + '&idRubro=' + selectedPalabra.idRubro + '&palabraClave=' + editedPalabra.toUpperCase() + '&idExactitud=2');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log(data);

                setSelectedPalabra('');
                setDisplayEditarExacta(false);
                setDisplayEditarPosible(false);
                loadData();
            } catch (error) {
                console.error(error);
            }
        };
        updateData();
    };
    const handleEliminarPalabra = () => {
        const deleteData = async () => {
            try {
                const response = await fetch(baseUrl + 'deletePalabraClave?idPalabraClave=' + selectedPalabra.id);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setSelectedPalabra('');
                setDisplayEliminarExacta(false);
                setDisplayEliminarPosible(false);
                loadData();
            } catch (error) {
                console.error(error);
            }
        };
        deleteData();
    };

    const handleAñadirPalabraExacta = () => {
        const insertData = async () => {
            try {
                const response = await fetch(baseUrl + 'insertPalabraClave?idRubro=' + idRubro + '&palabraClave=' + newPalabraExacta.toUpperCase() + '&idExactitud=1');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log(data);

                setNewPalabraExacta('');
                setDisplayAñadirExacta(false);
                loadData();
            } catch (error) {
                console.error(error);
            }
        };
        insertData();
    };
    const handleAñadirPalabraPosible = () => {
        const insertData = async () => {
            try {
                const response = await fetch(baseUrl + 'insertPalabraClave?idRubro=' + idRubro + '&palabraClave=' + newPalabraPosible.toUpperCase() + '&idExactitud=2');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setNewPalabraPosible('');
                setDisplayAñadirPosible(false);
                loadData();
            } catch (error) {
                console.error(error);
            }
        };
        insertData();
    };
    const loadData = async () => {
        try {
            const response = await fetch(baseUrl + 'getPalabrasClaveByIdClienteAndIdRubro?idCliente=' + props.clientId + '&idRubro=' + idRubro);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (Array.isArray(data.palabrasClave)) {
                setPalabras(data.palabrasClave);
            } else {
                console.error('Data.palabrasClave is not an array:', data.palabrasClave);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    const palabrasExactas = palabras.filter((item) => item.descripcion === 'EXACTA');
    const palabrasPosibles = palabras.filter((item) => item.descripcion === 'POSIBLE');

    return (
        <div className="grid table-demo">
            <div className="col-6">
                <div className="card">
                    <h5>Palabras Exactas</h5>
                    <Button label="Añadir Palabra" icon="pi pi-plus" className="p-button-sm p-button-primary mb-4" onClick={() => setDisplayAñadirExacta(true)} style={{ backgroundColor: 'green' }} />
                    <Dialog header="Añadir Palabra Exacta" visible={displayAñadirExacta} onHide={() => setDisplayAñadirExacta(false)}>
                        <div className="formgroup-inline">
                            <div className="field">
                                <label htmlFor="newPalabraExacta" className="p-sr-only"></label>
                                <InputText id="newPalabraExacta" autoComplete="off" autoCorrect="false" type="text" value={newPalabraExacta} onChange={(e) => setNewPalabraExacta(e.target.value)} />
                            </div>

                            <Button icon="pi pi-save" label="Guardar" onClick={handleAñadirPalabraExacta} />
                        </div>
                    </Dialog>
                    <DataTable dataKey="id" value={palabrasExactas} className="p-datatable-gridlines" showGridlines rows={10} filterDisplay="menu" responsiveLayout="scroll" emptyMessage="No tiene palabras asociadas.">
                        <Column field="nombre" header="Palabra" style={{ minWidth: '8rem' }} />
                        <Column
                            header="Acciones"
                            style={{ minWidth: '8rem' }}
                            body={(rowData) => (
                                <div>
                                    <Button label="Editar" icon="pi pi-pencil" className="p-button-sm p-button-primary mr-2 mt-2 mb-2" onClick={() => handleEditarPalabraExacta(rowData)} style={{ backgroundColor: 'rgb(29, 112, 183)' }} />
                                    <Dialog header={`Editar Palabra Exacta: ${selectedPalabra.nombre}`} visible={displayEditarExacta} style={{ width: '30vw' }} modal onHide={() => setDisplayEditarExacta(false)}>
                                        <div className="formgroup-inline">
                                            <div className="field">
                                                <label htmlFor="nombrePalabra" className="p-sr-only">
                                                    Palabra
                                                </label>
                                                <InputText autoComplete="off" id="nombrePalabra" type="text" value={editedPalabra} onChange={(e) => setEditedPalabra(e.target.value)} />{' '}
                                            </div>

                                            <Button icon="pi pi-save" label="Guardar" onClick={handleGuardarPalabraExacta} style={{ backgroundColor: 'rgb(29, 112, 183)' }} />
                                        </div>
                                    </Dialog>

                                    <Button label="Cambiar Exactitud" icon="pi pi-search" className="p-button-sm p-button-primary mr-2 mt-2 mb-2" onClick={() => handleCambiarExactitud(rowData)} style={{ backgroundColor: 'rgb(29, 112, 183)' }} />
                                    <Button
                                        label="Eliminar"
                                        icon="pi pi-trash"
                                        className="p-button-sm p-button-danger mr-2 mt-2 mb-2"
                                        onClick={() => {
                                            setSelectedPalabra(rowData);
                                            setDisplayEliminarExacta(true);
                                        }}
                                    />
                                    <Dialog header="Confirmación" visible={displayEliminarExacta} onHide={() => setDisplayEliminarExacta(false)} style={{ width: '350px' }} modal>
                                        <div className="flex align-items-center justify-content-center">
                                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                            <span>¿Seguro que desea eliminar {selectedPalabra.palabra}?</span>
                                        </div>
                                        <Button type="button" label="Sí" icon="pi pi-check" onClick={handleEliminarPalabra} className="p-button-text" autoFocus />
                                        <Button type="button" label="No" icon="pi pi-times" onClick={() => setDisplayEliminarExacta(false)} className="p-button-text" />
                                    </Dialog>
                                </div>
                            )}
                        />
                    </DataTable>
                </div>
            </div>
            <div className="col-6">
                <div className="card">
                    <h5>Palabras Posibles</h5>
                    <Button label="Añadir Palabra" icon="pi pi-plus" className="p-button-sm p-button-primary mb-4" onClick={() => setDisplayAñadirPosible(true)} style={{ backgroundColor: 'green' }} />
                    <Dialog header="Añadir Palabra Posible" visible={displayAñadirPosible} onHide={() => setDisplayAñadirPosible(false)}>
                        <div className="formgroup-inline">
                            <div className="field">
                                <label htmlFor="newPalabraExacta" className="p-sr-only"></label>
                                <InputText id="newPalabraExacta" autoComplete="off" type="text" value={newPalabraPosible} onChange={(e) => setNewPalabraPosible(e.target.value)} />
                            </div>
                            <Button icon="pi pi-save" label="Guardar" onClick={handleAñadirPalabraPosible} />
                        </div>
                    </Dialog>
                    <DataTable dataKey="id" value={palabrasPosibles} className="p-datatable-gridlines" showGridlines rows={10} filterDisplay="menu" responsiveLayout="scroll" emptyMessage="No tiene palabras asociadas.">
                        <Column field="nombre" header="Palabra" style={{ minWidth: '8rem' }} />
                        <Column
                            header="Acciones"
                            style={{ minWidth: '8rem' }}
                            body={(rowData) => (
                                <div>
                                    <Button label="Editar " icon="pi pi-pencil" className="p-button-sm p-button-primary mr-2 mt-2 mb-2" onClick={() => handleEditarPalabraPosible(rowData)} style={{ backgroundColor: 'rgb(29, 112, 183)' }} />
                                    {/* Edit Rubro Dialog */}
                                    <Dialog header={`Editar Palabra: ${selectedPalabra.nombre}`} visible={displayEditarPosible} style={{ width: '30vw' }} modal onHide={() => setDisplayEditarPosible(false)}>
                                        <div className="formgroup-inline">
                                            <div className="field">
                                                <label htmlFor="nombrePalabra" className="p-sr-only">
                                                    Palabra
                                                </label>
                                                <InputText id="nombrePalabra" autoComplete="off" type="text" value={editedPalabra} onChange={(e) => setEditedPalabra(e.target.value)} />{' '}
                                            </div>
                                            <Button icon="pi pi-save" label="Guardar" onClick={handleGuardarPalabraPosible} style={{ backgroundColor: 'rgb(29, 112, 183)' }} />
                                        </div>
                                    </Dialog>

                                    <Button label="Cambiar Exactitud" icon="pi pi-search" className="p-button-sm p-button-primary mr-2 mt-2 mb-2" onClick={() => handleCambiarExactitud(rowData)} style={{ backgroundColor: 'rgb(29, 112, 183)' }} />
                                    <Button
                                        label="Eliminar "
                                        icon="pi pi-trash"
                                        className="p-button-sm p-button-danger mr-2 mt-2 mb-2"
                                        onClick={() => {
                                            setSelectedPalabra(rowData);
                                            setDisplayEliminarPosible(true);
                                        }}
                                    />
                                    <Dialog header="Confirmación" visible={displayEliminarPosible} onHide={() => setDisplayEliminarPosible(false)} style={{ width: '350px' }} modal>
                                        <div className="flex align-items-center justify-content-center">
                                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                            <span>¿Seguro que desea eliminar {selectedPalabra.palabra}?</span>
                                        </div>
                                        <Button type="button" label="Sí" icon="pi pi-check" onClick={handleEliminarPalabra} className="p-button-text" autoFocus />
                                        <Button type="button" label="No" icon="pi pi-times" onClick={() => setDisplayEliminarPosible(false)} className="p-button-text" />
                                    </Dialog>
                                </div>
                            )}
                        />
                    </DataTable>
                </div>
            </div>
            <div className="col-6" style={{ display: 'none' }}>
                <div className="card">
                    <h5>Palabras Excluidas DEMO NO FUNCIONAL</h5>
                    <Button label="Añadir Palabra" icon="pi pi-plus" className="p-button-sm p-button-primary mb-4" onClick={() => setDisplayAñadirExacta(true)} style={{ backgroundColor: 'green' }} />
                    <Dialog header="Añadir Palabra Exacta" visible={displayAñadirExacta} onHide={() => setDisplayAñadirExacta(false)}>
                        <div className="formgroup-inline">
                            <div className="field">
                                <label htmlFor="newPalabraExacta" className="p-sr-only"></label>
                                <InputText id="newPalabraExacta" autoComplete="off" autoCorrect="false" type="text" value={newPalabraExacta} onChange={(e) => setNewPalabraExacta(e.target.value)} />
                            </div>

                            <Button icon="pi pi-save" label="Guardar" onClick={handleAñadirPalabraExacta} />
                        </div>
                    </Dialog>
                    <DataTable dataKey="id" value={palabrasExactas} className="p-datatable-gridlines" showGridlines rows={10} filterDisplay="menu" responsiveLayout="scroll" emptyMessage="No tiene palabras asociadas.">
                        <Column field="nombre" header="Palabra" style={{ minWidth: '8rem' }} />
                        <Column
                            header="Acciones"
                            style={{ minWidth: '8rem' }}
                            body={(rowData) => (
                                <div>
                                    <Button label="Editar " icon="pi pi-pencil" className="p-button-sm p-button-primary mr-2" onClick={() => handleEditarPalabraExacta(rowData)} style={{ backgroundColor: 'rgb(29, 112, 183)' }} />
                                    <Dialog header={`Editar Palabra Exacta: ${selectedPalabra.nombre}`} visible={displayEditarExacta} style={{ width: '30vw' }} modal onHide={() => setDisplayEditarExacta(false)}>
                                        <div className="formgroup-inline">
                                            <div className="field">
                                                <label htmlFor="nombrePalabra" className="p-sr-only">
                                                    Palabra
                                                </label>
                                                <InputText autoComplete="off" id="nombrePalabra" type="text" value={editedPalabra} onChange={(e) => setEditedPalabra(e.target.value)} />{' '}
                                            </div>

                                            <Button icon="pi pi-save" label="Guardar" onClick={handleGuardarPalabraExacta} style={{ backgroundColor: 'rgb(29, 112, 183)' }} />
                                        </div>
                                    </Dialog>

                                    <Button label="Cambiar Exactitud" icon="pi pi-search" className="p-button-sm p-button-primary" onClick={() => handleCambiarExactitud(rowData)} style={{ backgroundColor: 'rgb(29, 112, 183)' }} />
                                    <Button
                                        label="Eliminar "
                                        icon="pi pi-trash"
                                        className="p-button-sm p-button-danger ml-2"
                                        onClick={() => {
                                            setSelectedPalabra(rowData);
                                            setDisplayEliminarExacta(true);
                                        }}
                                    />
                                    <Dialog header="Confirmación" visible={displayEliminarExacta} onHide={() => setDisplayEliminarExacta(false)} style={{ width: '350px' }} modal>
                                        <div className="flex align-items-center justify-content-center">
                                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                            <span>¿Seguro que desea eliminar {selectedPalabra.palabra}?</span>
                                        </div>
                                        <Button type="button" label="Sí" icon="pi pi-check" onClick={handleEliminarPalabra} className="p-button-text" autoFocus />
                                        <Button type="button" label="No" icon="pi pi-times" onClick={() => setDisplayEliminarExacta(false)} className="p-button-text" />
                                    </Dialog>
                                </div>
                            )}
                        />
                    </DataTable>
                </div>
            </div>
            <div className="col-6" style={{ display: 'none' }}>
                <div className="card">
                    <h5>Palabras Excluidas DEMO NO FUNCIONAL</h5>
                    <Button label="Añadir Palabra" icon="pi pi-plus" className="p-button-sm p-button-primary mb-4" onClick={() => setDisplayAñadirExacta(true)} style={{ backgroundColor: 'green' }} />
                    <Dialog header="Añadir Palabra Exacta" visible={displayAñadirExacta} onHide={() => setDisplayAñadirExacta(false)}>
                        <div className="formgroup-inline">
                            <div className="field">
                                <label htmlFor="newPalabraExacta" className="p-sr-only"></label>
                                <InputText id="newPalabraExacta" autoComplete="off" autoCorrect="false" type="text" value={newPalabraExacta} onChange={(e) => setNewPalabraExacta(e.target.value)} />
                            </div>

                            <Button icon="pi pi-save" label="Guardar" onClick={handleAñadirPalabraExacta} />
                        </div>
                    </Dialog>
                    <DataTable dataKey="id" value={palabrasExactas} className="p-datatable-gridlines" showGridlines rows={10} filterDisplay="menu" responsiveLayout="scroll" emptyMessage="No tiene palabras asociadas.">
                        <Column field="nombre" header="Palabra" style={{ minWidth: '8rem' }} />
                        <Column
                            header="Acciones"
                            style={{ minWidth: '8rem' }}
                            body={(rowData) => (
                                <div>
                                    <Button label="Editar Palabra" icon="pi pi-pencil" className="p-button-sm p-button-primary mr-2" onClick={() => handleEditarPalabraExacta(rowData)} style={{ backgroundColor: 'rgb(29, 112, 183)' }} />
                                    <Dialog header={`Editar Palabra Exacta: ${selectedPalabra.nombre}`} visible={displayEditarExacta} style={{ width: '30vw' }} modal onHide={() => setDisplayEditarExacta(false)}>
                                        <div className="formgroup-inline">
                                            <div className="field">
                                                <label htmlFor="nombrePalabra" className="p-sr-only">
                                                    Palabra
                                                </label>
                                                <InputText autoComplete="off" id="nombrePalabra" type="text" value={editedPalabra} onChange={(e) => setEditedPalabra(e.target.value)} />{' '}
                                            </div>

                                            <Button icon="pi pi-save" label="Guardar" onClick={handleGuardarPalabraExacta} style={{ backgroundColor: 'rgb(29, 112, 183)' }} />
                                        </div>
                                    </Dialog>

                                    <Button label="Cambiar Exactitud" icon="pi pi-search" className="p-button-sm p-button-primary" onClick={() => handleCambiarExactitud(rowData)} style={{ backgroundColor: 'rgb(29, 112, 183)' }} />
                                    <Button
                                        label="Eliminar Palabra"
                                        icon="pi pi-trash"
                                        className="p-button-sm p-button-danger ml-2"
                                        onClick={() => {
                                            setSelectedPalabra(rowData);
                                            setDisplayEliminarExacta(true);
                                        }}
                                    />
                                    <Dialog header="Confirmación" visible={displayEliminarExacta} onHide={() => setDisplayEliminarExacta(false)} style={{ width: '350px' }} modal>
                                        <div className="flex align-items-center justify-content-center">
                                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                            <span>¿Seguro que desea eliminar {selectedPalabra.palabra}?</span>
                                        </div>
                                        <Button type="button" label="Sí" icon="pi pi-check" onClick={handleEliminarPalabra} className="p-button-text" autoFocus />
                                        <Button type="button" label="No" icon="pi pi-times" onClick={() => setDisplayEliminarExacta(false)} className="p-button-text" />
                                    </Dialog>
                                </div>
                            )}
                        />
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode && prevProps.isNewThemeLoaded === nextProps.isNewThemeLoaded && prevProps.onNewThemeChange === nextProps.onNewThemeChange;
};

export default React.memo(PalabrasClave, comparisonFn);
