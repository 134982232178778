import React, { useState } from 'react';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../Config';
import Loading from './Loading';

const Rubros = (props) => {
    const [rubros, setRubros] = useState([
        { id: 1, nombre: 'Rubro 1' },
        { id: 2, nombre: 'Rubro 2' }
    ]);

    const [displayBasic, setDisplayBasic] = useState(false);
    const [displayConfirmation, setDisplayConfirmation] = useState(false);
    const [selectedRubro, setSelectedRubro] = useState({});
    const [editedRubro, setEditedRubro] = useState('');
    const [newRubro, setNewRubro] = useState('');
    const [displayAnadir, setDisplayAnadir] = useState(false);
    const navigate = useNavigate();
    const { baseUrl } = useGlobalContext();
    const [loading, setLoading] = useState(false);

    const handleEditarRubro = (rubro) => {
        setSelectedRubro(rubro);
        setEditedRubro(rubro.nombre);
        setDisplayBasic(true);
    };
    const handleGuardarRubro = () => {
        const updateData = async () => {
            try {
                setLoading(true);
                const response = await fetch(baseUrl + 'updateRubro?idRubro=' + selectedRubro.id + '&rubro=' + editedRubro.toUpperCase());
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log(data);

                setSelectedRubro('');
                setDisplayBasic(false);
                loadData();
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        updateData();
    };
    const handleEliminarRubro = (rubro) => {
        const deleteData = async () => {
            try {
                setLoading(true);

                const response = await fetch(baseUrl + 'deleteRubro?idRubro=' + rubro.id);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log(data);

                setSelectedRubro('');
                setDisplayConfirmation(false);
                loadData();
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        deleteData();
    };
    const handleAnadirRubro = () => {
        const insertData = async () => {
            try {
                setLoading(true);

                const response = await fetch(baseUrl + 'insertRubro?idCliente=' + props.clientId + '&rubro=' + newRubro.toUpperCase());
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setNewRubro('');
                console.log(data);
                setDisplayAnadir(false);
                loadData();
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        insertData();
    };
    const handleVerPalabrasClave = (rubro) => {
        navigate('/palabrasClave?idRubro=' + rubro.id);
    };

    const loadData = async () => {
        try {
            setLoading(true);

            const response = await fetch(baseUrl + 'rubrosbyidcliente?idCliente=' + props.clientId);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            // Check if data.licitaciones is an array before setting the state
            if (Array.isArray(data.rubros)) {
                setRubros(data.rubros);
            } else {
                console.error('Data.rubros is not an array:', data.rubros);
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="grid table-demo">
            {loading ? (
                <Loading /> // Render Loading component when loading is true
            ) : (
                <div className="col-12">
                    <div className="card">
                        <h5>Rubros</h5>
                        <Button label="Añadir Rubro" icon="pi pi-plus" className="p-button-sm p-button-primary mb-4" onClick={() => setDisplayAnadir(true)} style={{ backgroundColor: 'green' }} />
                        <Dialog header="Añadir Rubro" visible={displayAnadir} onHide={() => setDisplayAnadir(false)}>
                            <div className="formgroup-inline">
                                <div className="field">
                                    <label htmlFor="newRubro" className="p-sr-only">
                                        Nombre Rubro
                                    </label>
                                    <InputText id="newRubro" type="text" placeholder="Nombre Rubro" value={newRubro} onChange={(e) => setNewRubro(e.target.value)} autoComplete="off" />
                                </div>
                                <Button icon="pi pi-save" label="Guardar" onClick={handleAnadirRubro} />
                            </div>
                        </Dialog>
                        <DataTable dataKey="id" value={rubros} className="p-datatable-gridlines" showGridlines rows={10} filterDisplay="menu" responsiveLayout="scroll" emptyMessage="No tiene rubros asociados.">
                            <Column field="nombre" header="Rubro" style={{ minWidth: '8rem' }} />
                            <Column
                                header="Acciones Rubro"
                                style={{ minWidth: '8rem' }}
                                body={(rowData) => (
                                    <div>
                                        <Button label="Editar Rubro" icon="pi pi-pencil" className="p-button-sm p-button-primary mr-2 mt-2 mb-2" onClick={() => handleEditarRubro(rowData)} style={{ backgroundColor: 'rgb(29, 112, 183)' }} />
                                        {/* Edit Rubro Dialog */}
                                        <Dialog header={`Editar Rubro: ${selectedRubro.nombre}`} visible={displayBasic} style={{ width: '30vw' }} modal onHide={() => setDisplayBasic(false)}>
                                            <div className="formgroup-inline">
                                                <div className="field">
                                                    <label htmlFor="nombreRubro" className="p-sr-only">
                                                        Nombre Rubro
                                                    </label>
                                                    <InputText
                                                        id="nombreRubro"
                                                        type="text"
                                                        placeholder="Nombre Rubro"
                                                        value={editedRubro}
                                                        onChange={(e) => setEditedRubro(e.target.value)} // Update editedRubro on input change
                                                        autoComplete="off"
                                                    />{' '}
                                                </div>
                                                <Button icon="pi pi-save" label="Guardar" onClick={handleGuardarRubro} style={{ backgroundColor: 'rgb(29, 112, 183)' }} />
                                            </div>
                                        </Dialog>
                                        <Button
                                            label="Eliminar Rubro"
                                            icon="pi pi-trash"
                                            className="p-button-sm p-button-danger mr-2 mt-2 mb-2"
                                            onClick={() => {
                                                setSelectedRubro(rowData);
                                                setDisplayConfirmation(true);
                                            }}
                                        />
                                        {/* Confirmation Dialog */}
                                        <Dialog header="Confirmación" visible={displayConfirmation} onHide={() => setDisplayConfirmation(false)} style={{ width: '350px' }} modal>
                                            <div className="flex align-items-center justify-content-center">
                                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                                <span>¿Seguro que desea eliminar {selectedRubro.nombre}?</span>
                                            </div>
                                            <Button type="button" label="Sí" icon="pi pi-check" onClick={() => handleEliminarRubro(rowData)} className="p-button-text" autoFocus />
                                            <Button type="button" label="No" icon="pi pi-times" onClick={() => setDisplayConfirmation(false)} className="p-button-text" />
                                        </Dialog>
                                    </div>
                                )}
                            />

                            <Column
                                header="Detalles Rubro"
                                style={{ minWidth: '8rem' }}
                                body={(rowData) => (
                                    <div>
                                        <Button label="Ver Palabras Clave" icon="pi pi-search" className="p-button-sm p-button-primary" style={{ backgroundColor: 'rgb(29, 112, 183)' }} onClick={() => handleVerPalabrasClave(rowData)} />
                                    </div>
                                )}
                            />
                        </DataTable>
                    </div>
                </div>
            )}
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode && prevProps.isNewThemeLoaded === nextProps.isNewThemeLoaded && prevProps.onNewThemeChange === nextProps.onNewThemeChange;
};

export default React.memo(Rubros, comparisonFn);
