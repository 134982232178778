import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';

import { Button } from 'primereact/button';

import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { useGlobalContext } from '../Config';
import Loading from './Loading';
import { Sidebar } from 'primereact/sidebar';
import '../assets/ultimate.css';
import * as XLSX from 'xlsx';

const Oportunidades = (props) => {
    const [checkboxValue, setCheckboxValue] = useState(['Exacto']);
    const [licitador, setLicitador] = useState(null);
    const [comprador, setComprador] = useState(null);
    const [rubro, setRubro] = useState(null);
    const [selectedRubro, setSelectedRubro] = useState(null);
    const [tipoLicitacion, setTipoLicitacion] = useState(null);
    const [region, setRegion] = useState(null);
    const [estado, setEstado] = useState(null);
    const [palabraClave, setPalabraClave] = useState('');
    const [estadoMercadoPublico, setEstadoMercadoPublico] = useState(['Publicada', 'Adjudicada']);
    const { baseUrl } = useGlobalContext();

    const [publicacionDesde, setPublicacionDesde] = useState(null);
    const [publicacionHasta, setPublicacionHasta] = useState(null);
    const currentDate = new Date();
    const [cierreDesde, setCierreDesde] = useState(currentDate);
    const [cierreHasta, setCierreHasta] = useState(null);

    const itemsPerPage = 20;
    const [currentPage, setCurrentPage] = useState(1);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const [displayedLicitaciones, setDisplayedLicitaciones] = useState('');
    const [totalPages, setTotalPages] = useState();
    const [totalLicitaciones, setTotalLicitaciones] = useState(0);
    const [loading, setLoading] = useState(false);
    const [visibleRight, setVisibleRight] = useState(false);

    const licitadores = [
        { name: 'Licitador 1', value: 'Licitador 1' },
        { name: 'Licitador 2', value: 'Licitador 2' },
        { name: 'Licitador 3', value: 'Licitador 3' }
    ];
    const compradores = [
        { name: 'Comprador 1', value: 'Comprador 1' },
        { name: 'Comprador 2', value: 'Comprador 2' },
        { name: 'Comprador 3', value: 'Comprador 3' }
    ];
    const [rubros, setRubros] = useState([{ id: 0, descripcion: 'TODOS', nombre: 'TODOS' }]);
    const tiposLicitacion = [
        { name: 'LE', value: 'LE' },
        { name: 'LE1', value: 'LE1' },
        { name: 'LE2', value: 'LE2' }
    ];
    const regiones = [
        { name: 'Region', value: 'Region' },
        { name: 'Region 1', value: 'Region 1' },
        { name: 'Region 2', value: 'Region 2' }
    ];
    const estados = [
        { name: 'Estado', value: 'Estado' },
        { name: 'Estado 1', value: 'Estado 1' },
        { name: 'Estado 2', value: 'Estado 2' }
    ];

    const convertDateToFilterFormat = (inputDate) => {
        let convertedDate;
        if (inputDate !== null && inputDate !== undefined) {
            const originalDate = new Date(inputDate);
            const year = originalDate.getFullYear();
            const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
            const day = originalDate.getDate().toString().padStart(2, '0');
            convertedDate = `${year}-${month}-${day}`;
        } else {
            convertedDate = null;
        }
        return convertedDate;
    };

    const applyFilters = () => {
        let exactitudAux;
        let estadoMercadoPublicoAux = [];
        switch (true) {
            case checkboxValue.includes('Exacto') && !checkboxValue.includes('Posible'):
                exactitudAux = 1;
                break;

            case checkboxValue.includes('Posible') && !checkboxValue.includes('Exacto'):
                exactitudAux = 2;
                break;

            case checkboxValue.includes('Todos'):
                exactitudAux = 3;
                break;

            default:
                exactitudAux = 0;
                break;
        }

        estadoMercadoPublico.forEach((estado) => {
            switch (estado) {
                case 'Publicada':
                    estadoMercadoPublicoAux.push(1);
                    break;

                case 'Cerrada':
                    estadoMercadoPublicoAux.push(2);
                    break;

                case 'Desierta':
                    estadoMercadoPublicoAux.push(3);
                    break;

                case 'Adjudicada':
                    estadoMercadoPublicoAux.push(4);
                    break;

                case 'Revocada':
                    estadoMercadoPublicoAux.push(5);
                    break;

                case 'Suspendida':
                    estadoMercadoPublicoAux.push(6);
                    break;

                default:
                    break;
            }
        });
        const fetchData = async () => {
            try {
                setLoading(true);
                let apiUrl = baseUrl + 'licitacionesbpc2';

                if (exactitudAux !== 3) {
                    const queryParams = [];

                    function addQueryParam(paramName, paramValue) {
                        if (paramValue !== null && paramValue !== undefined && paramValue !== '') {
                            queryParams.push(`${paramName}=${encodeURIComponent(paramValue)}`);
                        } else {
                            queryParams.push(`${paramName}= `);
                        }
                    }
                    function addQueryDateParam(paramName, paramValue) {
                        if (paramValue !== null && paramValue !== undefined && paramValue !== '') {
                            queryParams.push(`${paramName}=${encodeURIComponent(convertDateToFilterFormat(paramValue))}`);
                        } else {
                            queryParams.push(`${paramName}=${null}`);
                        }
                    }

                    addQueryParam('palabra', palabraClave);
                    addQueryParam('idCliente', props.clientId);
                    addQueryParam('exactitud', exactitudAux);
                    addQueryDateParam('publicacionDesde', publicacionDesde);
                    addQueryDateParam('publicacionHasta', publicacionHasta);
                    addQueryDateParam('cierreDesde', cierreDesde);
                    addQueryDateParam('cierreHasta', cierreHasta);

                    if (selectedRubro) {
                        queryParams.push(`idRubro=${selectedRubro.id}`);
                    } else {
                        queryParams.push(`idRubro= `);
                    }

                    if (estadoMercadoPublicoAux.length > 0) {
                        const estadoMercadoPublicoString = estadoMercadoPublicoAux.join(',');
                        queryParams.push(`estadoMercadoPublico=${estadoMercadoPublicoString}`);
                    } else {
                        queryParams.push('estadoMercadoPublico=1,2,3,4,5,6');
                    }

                    apiUrl += `?${queryParams.join('&')}`;
                } else {
                    setSelectedRubro({ id: 0, value: 'TODOS', name: 'TODOS' });
                    setRubro({ id: 0, value: 'TODOS', name: 'TODOS' });
                    setPublicacionDesde(null);
                    setPublicacionHasta(null);
                    apiUrl = baseUrl + '/licitaciones';
                }
                console.log(apiUrl);
                const response = await fetch(apiUrl);

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                if (Array.isArray(data.licitaciones)) {
                    setLicitaciones(data.licitaciones);
                    setDisplayedLicitaciones(licitaciones.slice(startIndex, endIndex));
                    setCurrentPage(1);
                    setTotalPages(Math.ceil(licitaciones.length / itemsPerPage));
                } else {
                    console.error('Data.licitaciones is not an array:', data.licitaciones);
                }
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
        setVisibleRight(false);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            setDisplayedLicitaciones(licitaciones.slice((currentPage - 1) * itemsPerPage, (currentPage - 1) * itemsPerPage + itemsPerPage));
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            setDisplayedLicitaciones(licitaciones.slice((currentPage - 1) * itemsPerPage, (currentPage - 1) * itemsPerPage + itemsPerPage));
        }
    };

    useEffect(() => {
        if (props.isNewThemeLoaded) {
            props.onNewThemeChange(false);
        }
    }, [props.isNewThemeLoaded, props.onNewThemeChange]); // eslint-disable-line react-hooks/exhaustive-deps

    const onCheckboxChange = (event) => {
        const { value, checked } = event.target;

        if (value === 'Todos') {
            setCheckboxValue(checked ? ['Todos'] : []);
        } else {
            setCheckboxValue([value]);
        }
    };

    const onEstadoMercadoPublicoChange = (event) => {
        const { value, checked } = event.target;

        const updatedState = [...estadoMercadoPublico];

        if (checked) {
            updatedState.push(value);
        } else {
            const index = updatedState.indexOf(value);
            if (index !== -1) {
                updatedState.splice(index, 1);
            }
        }

        setEstadoMercadoPublico(updatedState);
    };

    const formattedDate = (inputDate) => {
        const date = new Date(inputDate);
        const day = date.getUTCDate();
        const month = date.getUTCMonth() + 1;
        const year = date.getUTCFullYear();

        const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

        return formattedDate;
    };
    const [licitaciones, setLicitaciones] = useState('');
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const dateObject = new Date(cierreDesde);
                const year = dateObject.getFullYear();
                const month = dateObject.getMonth() + 1;
                const day = dateObject.getDate();
                const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

                const response = await fetch(
                    baseUrl +
                        'licitacionesbpc2?palabra=' +
                        palabraClave +
                        ' &idCliente=' +
                        props.clientId +
                        ' &exactitud=1&publicacionDesde=null' +
                        '&publicacionHasta=null' +
                        '&cierreDesde=' +
                        formattedDate +
                        '&cierreHasta=null' +
                        '&estadoMercadoPublico=1,4'
                );
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                if (Array.isArray(data.licitaciones)) {
                    setLicitaciones(data.licitaciones);
                } else {
                    console.error('Data.licitaciones is not an array:', data.licitaciones);
                }
                const rubros = await fetch(baseUrl + 'rubrosbyidcliente?idCliente=' + props.clientId);
                if (!rubros.ok) {
                    throw new Error('Network response was not ok');
                }
                const dataRubros = await rubros.json();
                if (Array.isArray(dataRubros.rubros)) {
                    const todosRubro = { id: 0, nombre: 'TODOS', descripcion: 'TODOS' };
                    const updatedRubros = [todosRubro, ...dataRubros.rubros];
                    setRubros(updatedRubros);
                } else {
                    console.error('Data.licitaciones is not an array:', dataRubros.rubros);
                }
                setLoading(false);
            } catch (error) {
                console.error(error);
            }
        };
        const getTotalLicitaciones = async () => {
            try {
                const response = await fetch(baseUrl + 'getCantLicitaciones');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setTotalLicitaciones(data.cantidad);
            } catch (error) {
                console.error(error);
            }
        };
        getTotalLicitaciones();
        fetchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setDisplayedLicitaciones(licitaciones.slice(startIndex, endIndex));
        setTotalPages(Math.ceil(licitaciones.length / itemsPerPage));
    }, [currentPage, licitaciones]);

    const handleOpen = (folio) => {
        window.open('https://www.mercadopublico.cl/Procurement/Modules/RFB/DetailsAcquisition.aspx?qs=' + folio);
    };

    const handleRubroChange = (e) => {
        setSelectedRubro(rubros.find((item) => item === e.value));
        setRubro(e.value);
    };

    const exportToExcel = (data) => {
        if (!Array.isArray(data)) {
            console.log('No es un Array.');
            return;
        }

        if (data.length === 0) {
            console.log('El Array está vacío, no hay nada para exportar.');
            return;
        }

        if (typeof data[0] !== 'object' || data[0] === null) {
            console.log('El Array debería contener objetos.');
            return;
        }

        const ws = XLSX.utils.json_to_sheet(data);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'licitaciones_mercado_agil.xlsx');
    };

    return (
        <div className="grid dashboard">
            <div className="col-12 md:col-12">
                {!loading && (
                    <div>
                        <h5>Resumen</h5>
                        <Button
                            type="button"
                            icon="pi pi-filter"
                            label="Ver Filtros"
                            className="p-button"
                            onClick={() => setVisibleRight(true)}
                            style={{ float: 'right', position: 'fixed', top: '20%', right: 0, backgroundColor: '#FBC02D', color: 'black' }}
                        />

                        <div className="grid dashboard" style={{ marginBottom: '30px', width: '100%' }}>
                            <div className="col-12 md:col-2 intern-box" style={{ textDecoration: 'none' }}>
                                <div className="card widget-social">
                                    <div className="flex justify-content-between align-items-center p-3">
                                        <div className="social-icon">
                                            <i className="pi pi-question blue-color fs-xxlarge"></i>
                                        </div>
                                        <div className="info flex flex-column">
                                            <span className="value">520</span>
                                            <span className="subtext mt-2">Sin gestionar</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-2 intern-box" style={{ textDecoration: 'none' }}>
                                <div className="card widget-social">
                                    <div className="flex justify-content-between align-items-center p-3">
                                        <div className="social-icon">
                                            <i className="pi pi-check orange-color fs-xxlarge"></i>
                                        </div>
                                        <div className="info flex flex-column">
                                            <span className="value">036</span>
                                            <span className="subtext mt-2">Revisadas</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-2 intern-box" style={{ textDecoration: 'none' }}>
                                <div className="card widget-social">
                                    <div className="flex justify-content-between align-items-center p-3">
                                        <div className="social-icon">
                                            <i className="pi pi-id-card fs-xxlarge " style={{ color: '#7ac943' }}></i>
                                        </div>
                                        <div className="info flex flex-column">
                                            <span className="value">025</span>
                                            <span className="subtext mt-2">Postuladas</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 md:col-2 intern-box" style={{ textDecoration: 'none' }}>
                                <div className="card widget-social">
                                    <div className="flex justify-content-between align-items-center p-3">
                                        <div className="social-icon">
                                            <i className="pi pi-check-square  fs-xxlarge" style={{ color: '#7ac943' }}></i>
                                        </div>
                                        <div className="info flex flex-column">
                                            <span className="value">058</span>
                                            <span className="subtext mt-2">Aprobadas</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 md:col-2 intern-box" style={{ textDecoration: 'none' }}>
                                <div className="card widget-social">
                                    <div className="flex justify-content-between align-items-center p-3">
                                        <div className="social-icon">
                                            <i className="pi pi-times  fs-xxlarge" style={{ color: 'red' }}></i>
                                        </div>
                                        <div className="info flex flex-column">
                                            <span className="value">058</span>
                                            <span className="subtext mt-2">Rechazadas</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {!loading && (
                    <div>
                        <h5>Oportunidades por rubro</h5>
                        <p>
                            Se encontraron {licitaciones.length} licitaciones de {totalLicitaciones}
                        </p>
                        <div className="grid dashboard" style={{ marginBottom: '30px', marginTop: '10px' }}>
                            <div className="col-12 md:col-5">
                                <Button onClick={handlePrevPage} disabled={currentPage === 1} className="mr-2" style={{ backgroundColor: 'rgb(29, 112, 183)' }}>
                                    Anterior
                                </Button>
                                <span className="ml-2 mr-2">
                                    Página {currentPage} de {totalPages}
                                </span>
                                <Button onClick={handleNextPage} disabled={currentPage === totalPages} className="ml-2 mr-2" style={{ backgroundColor: 'rgb(29, 112, 183)' }}>
                                    Siguiente
                                </Button>
                            </div>
                            <div className="col-12 md:col-5"></div>
                            <div className="col-12 md:col-2">
                                <Button onClick={() => exportToExcel(licitaciones)} icon="pi pi pi-file-excel" disabled={licitaciones.length <= 0} style={{ backgroundColor: 'green' }}>
                                    Generar Excel
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
                {loading ? (
                    <Loading />
                ) : (
                    Object.values(displayedLicitaciones).map((licitacion, index) => {
                        let content;
                        let bottomBar;
                        let fechaPublicacion;
                        let fechaApertura;
                        let fechaCierre;

                        const mapperEstado = {
                            1: { backgroundColor: '#7ac943', label: 'Publicada' },
                            2: { backgroundColor: '#FF9800', label: 'Cerrada' },
                            3: { backgroundColor: '#FBC02D', label: 'Desierta' },
                            4: { backgroundColor: '#2196F3', label: 'Adjudicada' },
                            5: { backgroundColor: 'red', label: 'Revocada' },
                            6: { backgroundColor: 'red', label: 'Suspendida' }
                        };

                        const infoEstado = mapperEstado[licitacion.idEstado];

                        const backgroundColor = infoEstado ? infoEstado.backgroundColor : 'grey';
                        const label = infoEstado ? infoEstado.label : 'Sin Estado';

                        content = (
                            <div>
                                <Tag className="ml-2" style={{ backgroundColor }} value={label} rounded></Tag>
                                <Tag className="ml-2" style={{ backgroundColor: 'white', color: 'black' }} value="Disponible" rounded></Tag>
                            </div>
                        );
                        bottomBar = <div style={{ backgroundColor, height: '3px' }}></div>;

                        if (licitacion.fechaPublicacion === undefined) {
                            fechaPublicacion = <div>No hay fecha</div>;
                        } else {
                            fechaPublicacion = <div>{formattedDate(licitacion.fechaPublicacion)}</div>;
                        }
                        if (licitacion.fechaInicioPreguntas === undefined) {
                            fechaApertura = <div>No hay fecha</div>;
                        } else {
                            fechaApertura = <div>{formattedDate(licitacion.fechaInicioPreguntas)}</div>;
                        }
                        if (licitacion.fechaCierre === undefined) {
                            fechaCierre = <div>No hay fecha</div>;
                        } else {
                            fechaCierre = <div>{formattedDate(licitacion.fechaCierre)}</div>;
                        }
                        return (
                            <React.Fragment key={index}>
                                <div className="card widget-social">
                                    <div className="flex justify-content-between align-items-center p-3">
                                        <div className="social-icon" style={{ maxWidth: '80%' }}>
                                            <span className="subtext mt-2">
                                                ID: {licitacion.idLicitacion} TIPO : {licitacion.tipoLicitacion} MANDANTE : {licitacion.idMandante}
                                                <br />
                                                <h5 style={{ color: '#1d70b7', fontWeight: 450, fontSize: '20px' }}>{licitacion.nombre}</h5>
                                                <h5 style={{ fontWeight: 450, fontSize: '12px' }}> {licitacion.descripcion}</h5>
                                            </span>
                                        </div>

                                        <div className="info flex flex-column">
                                            <span className="subtext mt-2">{content}</span>
                                        </div>
                                    </div>
                                    <div className="stats flex justify-content-between mt-3">
                                        <div className="right flex flex-column">
                                            <span className="title">Fecha de Publicación</span>
                                            <span className="value mb-2">{fechaPublicacion}</span>
                                        </div>
                                        <div className="right flex flex-column">
                                            <span className="title">Fecha de Cierre</span>
                                            <span className="value mb-2">{fechaCierre}</span>
                                        </div>
                                        <div className="right flex flex-column">
                                            <span className="title">Fecha de Foro</span>
                                            <span className="value mb-2">{fechaApertura}</span>
                                        </div>
                                    </div>
                                    <div className="stats flex justify-content-between mt-3">
                                        <div className="right flex flex-column">
                                            <span className="title">Monto</span>
                                            <span className="value mb-2">{licitacion.monto}</span>
                                        </div>
                                        <div className="right flex flex-column">
                                            <span className="title">Garantía</span>
                                            <span className="value mb-2"></span>
                                        </div>
                                        <div className="right flex flex-column">
                                            <span className="title"></span>
                                            <Button
                                                label="Ver Detalle"
                                                icon="pi pi-search"
                                                className="p-button-raised mr-2 mb-2"
                                                style={{ backgroundColor: 'rgb(29, 112, 183)' }}
                                                onClick={() => {
                                                    handleOpen(licitacion.folio);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div>{bottomBar}</div>
                                </div>
                            </React.Fragment>
                        );
                    })
                )}
            </div>

            <Sidebar visible={visibleRight} onHide={() => setVisibleRight(false)} baseZIndex={1000} position="right">
                <div className="card widget-social">
                    <div className="grid " style={{ display: 'none' }}>
                        <div className="col-12 mb-12 lg:col-7 lg:mb-0">
                            <InputText type="text" placeholder="Buscar palabras clave" value={palabraClave} onChange={(e) => setPalabraClave(e.target.value)} style={{ width: '100%' }}></InputText>
                        </div>
                    </div>
                    <div className="col-12 mb-12">
                        <Button label="Aplicar Filtros" icon="pi pi-search" className="p-button-raised mr-2 mb-2" style={{ backgroundColor: 'rgb(29, 112, 183)', width: '100%' }} onClick={applyFilters} />
                    </div>
                    {rubros.length > 1 && (
                        <div>
                            <div className="flex justify-content-between align-items-center p-3">
                                <div className="social-icon"></div>
                                <div className="info flex flex-column">
                                    <span className="subtext mt-2" style={{ color: '#1d70b7', fontWeight: 600 }}>
                                        Rubro
                                    </span>
                                </div>
                                <div className="stats flex justify-content-between mt-3"></div>
                            </div>
                            <div className="grid">
                                <div className="col-12 md:col-12">
                                    <div className="col-12 md:col-12">
                                        <Dropdown value={rubro} onChange={handleRubroChange} options={rubros} optionLabel="descripcion" placeholder="Seleccione Rubro" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}{' '}
                    <div className="flex justify-content-between align-items-center p-3">
                        <div className="social-icon"></div>
                        <div className="info flex flex-column">
                            <span className="subtext mt-2" style={{ color: '#1d70b7', fontWeight: 600 }}>
                                Tipo
                            </span>
                        </div>
                        <div className="stats flex justify-content-between mt-3"></div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-4">
                            <div className="field-checkbox">
                                <Checkbox type="checkbox" id="exacto" name="option" value="Exacto" checked={checkboxValue.includes('Exacto')} onChange={onCheckboxChange} />
                                <label htmlFor="exacto">Exacto</label>
                            </div>
                        </div>

                        <div className="col-12 md:col-4">
                            <div className="field-checkbox">
                                <Checkbox type="checkbox" id="posible" name="option" value="Posible" checked={checkboxValue.includes('Posible')} onChange={onCheckboxChange} />
                                <label htmlFor="posible">Posible</label>
                            </div>
                        </div>

                        <div className="col-12 md:col-4">
                            <div className="field-checkbox">
                                <Checkbox type="checkbox" id="todos" name="option" value="Todos" checked={checkboxValue.includes('Todos')} onChange={onCheckboxChange} />
                                <label htmlFor="todos">Todos</label>
                            </div>
                        </div>
                    </div>
                    <div className="stats flex justify-content-between mt-3"></div>
                    <div className="flex justify-content-between align-items-center p-3">
                        <div className="social-icon"></div>
                        <div className="info flex flex-column">
                            <span className="subtext mt-2" style={{ color: '#1d70b7', fontWeight: 600 }}>
                                Estado Interno
                            </span>
                        </div>
                        <div className="stats flex justify-content-between mt-3"></div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12">
                            <div className="field-checkbox">
                                <Checkbox inputId="postulada" name="option" value="Postulada" checked={checkboxValue.indexOf('Postulada') !== -1} onChange={onCheckboxChange} />
                                <label htmlFor="postulada">Postulada</label>
                            </div>
                        </div>

                        <div className="col-12 md:col-12">
                            <div className="field-checkbox">
                                <Checkbox inputId="revision" name="option" value="En revisión" checked={checkboxValue.indexOf('En revisión') !== -1} onChange={onCheckboxChange} />
                                <label htmlFor="revision">En revisión</label>
                            </div>
                        </div>
                        <div className="col-12 md:col-12">
                            <div className="field-checkbox">
                                <Checkbox inputId="sinGestionar" name="option" value="Sin gestionar" checked={checkboxValue.indexOf('Sin gestionar') !== -1} onChange={onCheckboxChange} />
                                <label htmlFor="sinGestionar">Sin gestionar</label>
                            </div>
                        </div>
                        <div className="col-12 md:col-12">
                            <div className="field-checkbox">
                                <Checkbox inputId="rechazadas" name="option" value="Rechazadas" checked={checkboxValue.indexOf('Rechazadas') !== -1} onChange={onCheckboxChange} />
                                <label htmlFor="rechazadas">Rechazadas</label>
                            </div>
                        </div>
                        <div className="col-12 md:col-12">
                            <div className="field-checkbox">
                                <Checkbox inputId="aprobadas" name="option" value="Aprobadas" checked={checkboxValue.indexOf('Aprobadas') !== -1} onChange={onCheckboxChange} />
                                <label htmlFor="aprobadas">Aprobadas</label>
                            </div>
                        </div>
                    </div>
                    <div className="stats flex justify-content-between mt-3"></div>
                    <div className="flex justify-content-between align-items-center p-3">
                        <div className="social-icon"></div>
                        <div className="info flex flex-column">
                            <span className="subtext mt-2" style={{ color: '#1d70b7', fontWeight: 600 }}>
                                Estado Mercado Público
                            </span>
                        </div>
                        <div className="stats flex justify-content-between mt-3"></div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12">
                            <div className="field-checkbox">
                                <Checkbox type="checkbox" inputId="publicada" name="option" value="Publicada" checked={estadoMercadoPublico.indexOf('Publicada') !== -1} onChange={onEstadoMercadoPublicoChange} />
                                <label htmlFor="publicada">Publicada</label>
                            </div>
                        </div>

                        <div className="col-12 md:col-12">
                            <div className="field-checkbox">
                                <Checkbox inputId="cerrada" name="option" value="Cerrada" checked={estadoMercadoPublico.indexOf('Cerrada') !== -1} onChange={onEstadoMercadoPublicoChange} />
                                <label htmlFor="cerrada">Cerrada</label>
                            </div>
                        </div>
                        <div className="col-12 md:col-12">
                            <div className="field-checkbox">
                                <Checkbox inputId="desierta" name="option" value="Desierta" checked={estadoMercadoPublico.indexOf('Desierta') !== -1} onChange={onEstadoMercadoPublicoChange} />
                                <label htmlFor="desierta">Desierta</label>
                            </div>
                        </div>
                        <div className="col-12 md:col-12">
                            <div className="field-checkbox">
                                <Checkbox inputId="adjudicada" name="option" value="Adjudicada" checked={estadoMercadoPublico.indexOf('Adjudicada') !== -1} onChange={onEstadoMercadoPublicoChange} />
                                <label htmlFor="adjudicada">Adjudicada</label>
                            </div>
                        </div>
                        <div className="col-12 md:col-12">
                            <div className="field-checkbox">
                                <Checkbox inputId="revocada" name="option" value="Revocada" checked={estadoMercadoPublico.indexOf('Revocada') !== -1} onChange={onEstadoMercadoPublicoChange} />
                                <label htmlFor="revocada">Revocada</label>
                            </div>
                        </div>
                        <div className="col-12 md:col-12">
                            <div className="field-checkbox">
                                <Checkbox inputId="suspendida" name="option" value="Suspendida" checked={estadoMercadoPublico.indexOf('Suspendida') !== -1} onChange={onEstadoMercadoPublicoChange} />
                                <label htmlFor="suspendida">Suspendida</label>
                            </div>
                        </div>
                    </div>
                    <div className="stats flex justify-content-between mt-3"></div>
                    <div className="flex justify-content-between align-items-center p-3">
                        <div className="social-icon"></div>
                        <div className="info flex flex-column">
                            <span className="subtext mt-2" style={{ color: '#1d70b7', fontWeight: 600 }}>
                                Presupuesto
                            </span>
                        </div>
                        <div className="stats flex justify-content-between mt-3"></div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12">
                            <div className="field-checkbox">
                                <Checkbox inputId="noPublicado" name="option" value="No publicado" checked={checkboxValue.indexOf('No publicado') !== -1} onChange={onCheckboxChange} />
                                <label htmlFor="noPublicado">No publicado</label>
                            </div>
                        </div>

                        <div className="col-12 md:col-12">
                            <div className="field-checkbox">
                                <Checkbox inputId="publicado" name="option" value="Publicado" checked={checkboxValue.indexOf('Publicado') !== -1} onChange={onCheckboxChange} />
                                <label htmlFor="publicado">Publicado</label>
                            </div>
                        </div>
                    </div>
                    <div className="stats flex justify-content-between mt-3"></div>
                    <div className="flex justify-content-between align-items-center p-3">
                        <div className="social-icon"></div>
                        <div className="info flex flex-column">
                            <span className="subtext mt-2" style={{ color: '#1d70b7', fontWeight: 600 }}>
                                Garantía
                            </span>
                        </div>
                        <div className="stats flex justify-content-between mt-3"></div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12">
                            <div className="field-checkbox">
                                <Checkbox inputId="sinGarantia" name="option" value="Sin Garntías" checked={checkboxValue.indexOf('Sin Garntías') !== -1} onChange={onCheckboxChange} />
                                <label htmlFor="sinGarantia">Sin Garntías</label>
                            </div>
                        </div>

                        <div className="col-12 md:col-12">
                            <div className="field-checkbox">
                                <Checkbox inputId="seriedad" name="option" value="Seriedad de las ofertas" checked={checkboxValue.indexOf('Seriedad de las ofertas') !== -1} onChange={onCheckboxChange} />
                                <label htmlFor="seriedad">Seriedad de las ofertas</label>
                            </div>
                        </div>
                        <div className="col-12 md:col-12">
                            <div className="field-checkbox">
                                <Checkbox inputId="otrasGarantias" name="option" value="Otras garantías" checked={checkboxValue.indexOf('Otras garantías') !== -1} onChange={onCheckboxChange} />
                                <label htmlFor="otrasGarantias">Otras garantías</label>
                            </div>
                        </div>
                    </div>
                    <div className="stats flex justify-content-between mt-3"></div>
                    <div className="flex justify-content-between align-items-center p-3">
                        <div className="social-icon"></div>
                        <div className="info flex flex-column">
                            <span className="subtext mt-2" style={{ color: '#1d70b7', fontWeight: 600 }}>
                                Fecha de publicación
                            </span>
                        </div>
                        <div className="stats flex justify-content-between mt-3"></div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12">
                            <label htmlFor="checkOption3">Desde</label>
                        </div>
                        <div className="col-12 md:col-12">
                            <Calendar showIcon showButtonBar value={publicacionDesde} onChange={(e) => setPublicacionDesde(e.value)} dateFormat="dd/mm/yy"></Calendar>
                        </div>
                        <div className="col-12 md:col-12">
                            <label htmlFor="checkOption3">Hasta</label>
                        </div>
                        <div className="col-12 md:col-12">
                            <div className="field-checkbox">
                                <Calendar showIcon showButtonBar value={publicacionHasta} onChange={(e) => setPublicacionHasta(e.value)} dateFormat="dd/mm/yy"></Calendar>
                            </div>
                        </div>
                    </div>
                    <div className="stats flex justify-content-between mt-3"></div>
                    <div className="flex justify-content-between align-items-center p-3">
                        <div className="social-icon"></div>
                        <div className="info flex flex-column">
                            <span className="subtext mt-2" style={{ color: '#1d70b7', fontWeight: 600 }}>
                                Fecha de cierre
                            </span>
                        </div>
                        <div className="stats flex justify-content-between mt-3"></div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12">
                            <label htmlFor="desde">Desde</label>
                        </div>
                        <div className="col-12 md:col-12">
                            <Calendar showIcon showButtonBar value={cierreDesde} onChange={(e) => setCierreDesde(e.value)} dateFormat="dd/mm/yy"></Calendar>
                        </div>
                        <div className="col-12 md:col-12">
                            <label htmlFor="hasta">Hasta</label>
                        </div>
                        <div className="col-12 md:col-12">
                            <div className="field-checkbox">
                                <Calendar showIcon showButtonBar value={cierreHasta} onChange={(e) => setCierreHasta(e.value)} dateFormat="dd/mm/yy"></Calendar>
                            </div>
                        </div>
                        <div className="col-12 md:col-12">
                            <div className="field-checkbox">
                                <Checkbox inputId="esteMes" name="option" value="Este mes" checked={checkboxValue.indexOf('Este mes') !== -1} onChange={onCheckboxChange} />
                                <label htmlFor="esteMes">Este mes</label>
                            </div>
                        </div>

                        <div className="col-12 md:col-12">
                            <div className="field-checkbox">
                                <Checkbox inputId="proximoMes" name="option" value="Próximo mes" checked={checkboxValue.indexOf('Próximo mes') !== -1} onChange={onCheckboxChange} />
                                <label htmlFor="proximoMes">Próximo mes</label>
                            </div>
                        </div>
                        <div className="col-12 md:col-12">
                            <div className="field-checkbox">
                                <Checkbox inputId="3meses" name="option" value="3 meses o más" checked={checkboxValue.indexOf('3 meses o más') !== -1} onChange={onCheckboxChange} />
                                <label htmlFor="3meses">3 meses o más</label>
                            </div>
                        </div>
                    </div>
                    <div className="stats flex justify-content-between mt-3"></div>
                    <div className="flex justify-content-between align-items-center p-3">
                        <div className="social-icon"></div>
                        <div className="info flex flex-column">
                            <span className="subtext mt-2" style={{ color: '#1d70b7', fontWeight: 600 }}>
                                Licitador / Vendedor
                            </span>
                        </div>
                        <div className="stats flex justify-content-between mt-3"></div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12">
                            <Dropdown value={licitador} onChange={(e) => setLicitador(e.value)} options={licitadores} optionLabel="name" placeholder="Selecciona Licitador" />
                        </div>
                    </div>
                    <div className="stats flex justify-content-between mt-3"></div>
                    <div className="flex justify-content-between align-items-center p-3">
                        <div className="social-icon"></div>
                        <div className="info flex flex-column">
                            <span className="subtext mt-2" style={{ color: '#1d70b7', fontWeight: 600 }}>
                                Comprador
                            </span>
                        </div>
                        <div className="stats flex justify-content-between mt-3"></div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12">
                            <Dropdown value={comprador} onChange={(e) => setComprador(e.value)} options={compradores} optionLabel="name" placeholder="Selecciona Comprador" />
                        </div>
                    </div>
                    <div className="stats flex justify-content-between mt-3"></div>
                    <div className="flex justify-content-between align-items-center p-3">
                        <div className="social-icon"></div>
                        <div className="info flex flex-column">
                            <span className="subtext mt-2" style={{ color: '#1d70b7', fontWeight: 600 }}>
                                Tipo de licitación
                            </span>
                        </div>
                        <div className="stats flex justify-content-between mt-3"></div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12">
                            <Dropdown value={tipoLicitacion} onChange={(e) => setTipoLicitacion(e.value)} options={tiposLicitacion} optionLabel="name" placeholder="Selecciona Tipo" />
                        </div>
                    </div>
                    <div className="stats flex justify-content-between mt-3"></div>
                    <div className="flex justify-content-between align-items-center p-3">
                        <div className="social-icon"></div>
                        <div className="info flex flex-column">
                            <span className="subtext mt-2" style={{ color: '#1d70b7', fontWeight: 600 }}>
                                Región
                            </span>
                        </div>
                        <div className="stats flex justify-content-between mt-3"></div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12">
                            <Dropdown value={region} onChange={(e) => setRegion(e.value)} options={regiones} optionLabel="name" placeholder="Selecciona Región" />
                        </div>
                    </div>
                    <div className="flex justify-content-between align-items-center p-3">
                        <div className="social-icon"></div>
                        <div className="info flex flex-column">
                            <span className="subtext mt-2" style={{ color: '#1d70b7', fontWeight: 600 }}>
                                Estado
                            </span>
                        </div>
                        <div className="stats flex justify-content-between mt-3"></div>
                    </div>
                    <div className="grid">
                        <div className="col-12 md:col-12">
                            <Dropdown value={estado} onChange={(e) => setEstado(e.value)} options={estados} optionLabel="name" placeholder="Selecciona Estado" />
                        </div>
                    </div>
                    <div className="stats flex justify-content-between mt-3"></div>
                    <div className="grid " style={{ display: 'none' }}>
                        <div className="col-12 mb-2 lg:col-7 lg:mb-0 mt-4">
                            <InputText type="text" placeholder="Buscar palabras clave" value={palabraClave} onChange={(e) => setPalabraClave(e.target.value)}></InputText>
                        </div>
                        <div className="col-12 mb-2 lg:col-5 lg:mb-0 mt-4">
                            <Button label="Aplicar Filtros" icon="pi pi-search" className="p-button-raised mr-2 mb-2" style={{ backgroundColor: 'rgb(29, 112, 183)' }} onClick={applyFilters} />
                        </div>
                    </div>
                </div>{' '}
            </Sidebar>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname && prevProps.colorMode === nextProps.colorMode && prevProps.isNewThemeLoaded === nextProps.isNewThemeLoaded && prevProps.onNewThemeChange === nextProps.onNewThemeChange;
};

export default React.memo(Oportunidades, comparisonFn);
